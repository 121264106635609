import React, { useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Form,
  // message,
  Button,
  Modal,
  message,
  Upload,
  DatePicker,
  Select,
  Checkbox,
  Spin,
} from "antd";
import API_MANAGER from "../../../API";
import DocumentBox from "../../../components/common/DocumentBox";
import {
  EditFilled,
  MinusOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { s3PreUrl } from "../../../constants";
import axios from "axios";
import dayjs from "dayjs";
function AdditionalDetailEdit({
  data,
  additionalEdit,
  setAdditionalEdit,
  userId,
  certifyingAuthority,
  stateTags,
  fundName,
  shirt,
  pants,
  boots,
}) {
  const [additionEditForm] = Form.useForm();
  const [editDocument, setEditDocument] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isSuperannuation, setIsSuperannuation] = useState(
    data?.isSuperannuation
  );
  const [isLSL, setIsLSL] = useState(data?.isLSL);
  const [hasTravelDetails, setHasTravelDetails] = useState(
    data?.hasTravelDetails
  );
  const [isPPE, setIsPPE] = useState(data?.isPPE);
  const [hasPoliceClearance, setHasPoliceClearance] = useState(
    data?.hasPoliceClearance
  );
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const addRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        state: { name: "", id: "" }, // Empty state values for new row
        memberNumber: "",
      },
    ]);
  };
  useEffect(() => {
    const transformedArray = data?.lslDetails?.map(
      ({ state, memberNumber }) => ({
        state: { name: state.name, id: state.id },
        memberNumber,
      })
    );
    setRows(transformedArray);
  }, [data]);
  const props = {
    name: "file",
    accept: "image/jpg,image/jpeg,.pdf",
    multiple: false,
    customRequest({ onSuccess }) {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const handleUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const handleFinish = async (values) => {
    console.log("values: ", values);
    setLoading(true);

    try {
      // Validate LSL details if checked
      if (isLSL) {
        const hasInvalidRow = rows.some(
          ({ state, memberNumber }) => !state.id || !memberNumber
        );
        if (hasInvalidRow) {
          message.error("Please fill in both State and Member Number fields.");
          setLoading(false);
          return; // Prevent submission
        }
      }

      const dataDocument = {
        extension: fileList?.length > 0 ? fileList[0]?.type.split("/")[1] : "",
        type: "DOCUMENT",
        name: fileList?.length > 0 ? fileList[0].originFileObj.name : "",
        contentType: fileList?.length > 0 ? fileList[0]?.type : "",
      };

      const responseDocument = await API_MANAGER.fileUpload(dataDocument);
      await axios.put(
        responseDocument?.data?.data?.url,
        fileList[0]?.originFileObj
      );

      values.userId = userId;
      let objData = {
        userId: values?.userId,
        tfnNo: values?.tfnNo,
        usiNo: values?.usiNo,
        isSuperannuation: isSuperannuation,
        isLSL: isLSL,
        isPPE: isPPE,
        hasTravelDetails: hasTravelDetails,
        hasPoliceClearance: hasPoliceClearance,
      };

      if (isSuperannuation) {
        objData.superannuationDetails = {
          fundName: values.fundName.id,
          fundUsiAbn: values.fundUsiAbn,
          memberNumber: values.fundMemberNumber,
        };
      }

      if (isLSL) {
        objData.lslDetails = rows.map(({ state, memberNumber }) => ({
          state: state.id,
          memberNumber,
        }));
      }

      if (isPPE) {
        objData.ppeDetails = {
          shirt: values.shirt,
          pants: values.pants,
          boots: values.boots,
        };
      }

      console.log("dddddddd", objData, values);
      if (hasTravelDetails) {
        objData.travelDetails = {
          preferredAirline: values.preferredAirline,
          frequentFlyerNumber: values.frequentFlyerNumber,
        };
      }

      if (hasPoliceClearance) {
        objData.policeClearanceDetails = {
          document:
            fileList?.length > 0
              ? s3PreUrl + responseDocument.data?.data?.key
              : data?.policeClearanceDetails?.document,
          certifyingAuthority: values.certifyingAuthority,
          issueDate: values?.issueDate,
        };
      }

      console.log("pppppppppp", objData);
      await API_MANAGER.editCandidateAdditionalDetails(objData);
      message.success("Successfully edited additional details");
      setLoading(false);
      setAdditionalEdit(false);
    } catch (error) {
      message.error(error.response?.data?.message?.message || "Error occurred");
    } finally {
      setLoading(false);
    }
  };
  console.log("data", data);
  const setFields = () => {
    additionEditForm.setFieldsValue({
      tfnNo: data?.tfnNo,
      usiNo: data?.usiNo,
      fundName: data?.superannuationDetails?.fundName,
      certifyingAuthority:
        data?.policeClearanceDetails?.certifyingAuthority?._id,
      fundMemberNumber: data?.superannuationDetails?.memberNumber,
      state: data?.lslDetails?.state,
      memberNumber: data?.lslDetails?.memberNumber,
      pants: data?.ppeDetails?.pants?.id,
      shirt: data?.ppeDetails?.shirt?.id,
      boots: data?.ppeDetails?.boots?.id,
      frequentFlyerNumber: data?.travelDetails?.frequentFlyerNumber,
      preferredAirline: data?.travelDetails?.preferredAirline,
      issueDate: data?.policeClearanceDetails?.issueDate
        ? dayjs(data?.policeClearanceDetails?.issueDate)
        : null,
    });
  };
  useEffect(() => {
    setFields();
  }, []);
  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    if (field === "state") {
      const selectedState = stateTags.find((e) => e.id === value);
      updatedRows[index].state = { name: selectedState.name, id: value };
    } else {
      updatedRows[index].memberNumber = value;
    }
    setRows(updatedRows);
  };
  const removeRow = (index) => {
    setRows((prevRows) => prevRows.filter((_, i) => i !== index));
  };
  return (
    <Modal
      centered
      footer={false}
      open={additionalEdit}
      title={"Additional Personal Details Edit"}
      className="resumeModal"
      onCancel={() => setAdditionalEdit(false)}
    >
      <Form form={additionEditForm} layout="vertical" onFinish={handleFinish}>
        <Spin spinning={loading} />
        <Row gutter={24}>
          <Col xs={12}>
            <Form.Item label="Tax File Number (TFN)" name="tfnNo">
              <Input placeholder="Tax File Number (TFN)" />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <Form.Item label="USI No." name="usiNo">
              <Input placeholder="USI No." />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Checkbox
              checked={isSuperannuation}
              onChange={() => setIsSuperannuation(!isSuperannuation)}
              className="title"
            >
              Superannuation
            </Checkbox>
          </Col>
        </Row>
        {isSuperannuation && (
          <>
            {" "}
            <Row gutter={24}>
              <Col xs={12}>
                <Form.Item label="Fund Name" name="fundName">
                  <Select className="" placeholder="Fund Name">
                    {fundName?.map((e, key) => {
                      return (
                        <Select.Option key={key} value={e?.id}>
                          {e?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label="Member Number" name="fundMemberNumber">
                  <Input placeholder="Member Number" />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col xs={24}>
            <Checkbox
              checked={isLSL}
              onChange={() => setIsLSL(!isLSL)}
              className="title"
            >
              Long Service Leave
            </Checkbox>
          </Col>
        </Row>
        {isLSL && (
          <>
            {rows.map((row, index) => (
              <Row key={index} gutter={[16, 16]} align="middle">
                <Col xs={10}>
                  <Form.Item label="State">
                    <Select
                      value={row.state.id || undefined}
                      placeholder="State"
                      onChange={(value) => handleChange(index, "state", value)}
                    >
                      {stateTags?.map((e) => (
                        <Select.Option key={e.id} value={e.id}>
                          {e.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={10}>
                  <Form.Item label="Member Number">
                    <Input
                      value={row.memberNumber}
                      placeholder="Member Number"
                      onChange={(e) =>
                        handleChange(index, "memberNumber", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={2}>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={addRow}
                  />
                </Col>
                <Col xs={2}>
                  <Button
                    type="danger"
                    icon={<MinusOutlined />}
                    onClick={() => removeRow(index)}
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
        <Row>
          <Col xs={24}>
            <Checkbox
              checked={isPPE}
              onChange={() => setIsPPE(!isPPE)}
              className="title"
            >
              PPE Details
            </Checkbox>
          </Col>
        </Row>
        {isPPE && (
          <Row gutter={24}>
            <Col xs={8}>
              <Form.Item label="Shirt" name="shirt">
                <Select className="" placeholder="Shirt">
                  {shirt?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e?._id}>
                        {e?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label="Pants" name="pants">
                <Select className="" placeholder="Pants">
                  {pants?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e?._id}>
                        {e?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item label="Boots" name="boots">
                <Select className="" placeholder="Boots">
                  {boots?.map((e, key) => {
                    return (
                      <Select.Option key={key} value={e?._id}>
                        {e?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24}>
            <Checkbox
              checked={hasTravelDetails}
              onChange={() => setHasTravelDetails(!hasTravelDetails)}
              className="title"
            >
              Travel Details
            </Checkbox>
          </Col>
        </Row>
        {hasTravelDetails && (
          <Row gutter={24}>
            <Col xs={12}>
              <Form.Item label="Preferred Airline" name="preferredAirline">
                <Input placeholder="Preferred Airline" />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item
                label="Frequent Flyer Number"
                name="frequentFlyerNumber"
              >
                <Input placeholder="Frequent Flyer Number" />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={24}>
            <Checkbox
              checked={hasPoliceClearance}
              onChange={() => setHasPoliceClearance(!hasPoliceClearance)}
              className="title"
            >
              Police Clearance
            </Checkbox>
          </Col>
        </Row>
        {hasPoliceClearance && (
          <>
            <Row>
              <Col xs={18}>
                {editDocument ? (
                  <Upload {...props} onChange={handleUpload}>
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                ) : (
                  <DocumentBox
                    date={data?.policeClearanceDetails?.issueDate}
                    url={data?.policeClearanceDetails?.document}
                  />
                )}
              </Col>
              <Col className="editResume" xs={6}>
                <EditFilled onClick={() => setEditDocument(true)} />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <Form.Item
                  label="Certifying Authority"
                  name="certifyingAuthority"
                >
                  <Select className="" placeholder="Certifying Authority">
                    {certifyingAuthority?.map((e, key) => {
                      return (
                        <Select.Option key={key} value={e?.id}>
                          {e?.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label="Issue Date" name="issueDate">
                  <DatePicker
                    className="w-100"
                    format={"DD-MM-YYYY"}
                    placeholder="Select issue date"
                  />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col xs={24} className="buttonCol">
            <Button className="btn1" htmlType="submit">
              Update
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
export default AdditionalDetailEdit;
