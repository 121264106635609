import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import FileIcon from "../../Assets/document/file.svg";
import OpenIcon from "../../Assets/document/open.svg";
import moment from "moment";
import { getFileNameFromUrl, getFileSizeFromUrl } from "../../constants";

function DocumentBox({ date, url }) {
  const [fileSize, setFileSize] = useState("");
  const getFileSize = async () => {
    const size = await getFileSizeFromUrl(url);
    setFileSize(size);
  };
  useEffect(() => {
    getFileSize();
  }, [url]);

  return (
    <div
      className="document_box_container"
      style={{
        border: "2px solid #e4e8ed",
        borderRadius: "12px",
        padding: "4px",
      }}
    >
      <Row className="cursor-pointer" onClick={() => window.open(url)}>
        <Col span={21}>
          <Row align={"middle"} gutter={24}>
            <Col xs={4}>
              <img src={FileIcon} alt="file" onClick={() => window.open(url)} />
            </Col>
            <Col xs={20}>
              <Col
                xs={22}
                className="title"
                style={{ marginBottom: "5px", wordBreak: "break-word" }}
              >
                {url ? getFileNameFromUrl(url) : "DOCUMENT"}
              </Col>
              <Col xs={22} className="size" style={{ marginBottom: "5px" }}>
                {fileSize} MB
              </Col>
              <Col xs={22} className="date" style={{ marginBottom: "5px" }}>
                {date ? moment(date)?.format("DD MMM YYYY") : "----"}
              </Col>
            </Col>
          </Row>
        </Col>
        <Col span={3}>
          <Row justify={"end"}>
            <img src={OpenIcon} alt="open" onClick={() => window.open(url)} />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default DocumentBox;
