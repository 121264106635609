import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Radio,
  Divider,
  Collapse,
  Table,
  message,
  Button,
  Modal,
  Form,
  Input,
  Avatar,
} from "antd";
import MailICon from "../../../Assets/profile/mail.svg";
import PhoneIcon from "../../../Assets/profile/phone.svg";
import AddressIcon from "../../../Assets/profile/address.svg";
import DocumentBox from "../DocumentBox";
import TelegramIcon from "../../../Assets/telegramIcon.png";
import JobPositionCard from "../campaign/JobPositionCard";
import { UpdateApplicantProfileStatus } from "../Popup";
import CustomPagination from "../../common/CustomPagination";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import API_MANAGER from "../../../API";
import GreenTick from "../../../Assets/client/GreenTracker.svg";
import RedCross from "../../../Assets/client/RedTracker.svg";
import { UserOutlined } from "@ant-design/icons";

function CandidateDetail() {
  const [type, setType] = useState("PE");
  const [citizen, setCitizen] = useState("australian");
  const [updateStatusModal, setUpdateStatusModal] = useState(false);
  // const [validateButtonState, setValidateButtonState] = useState(true);
  const [notify, setNotify] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [commData, setCommData] = useState([]);
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [verifyData, setVerifyData] = useState({});
  const [verifyType, setVerifyType] = useState("");
  const [form1] = Form.useForm();
  const navigate = useNavigate();
  const progressState = (state) => {
    if (state === 0) return "APPLIED";
    else if (state === 1) {
      return "ACCEPTED";
    } else if (state === 2) {
      return "SHORTLIST_OFFERED";
    } else if (state === 3) {
      return "HARD_REVIEW";
    } else if (state === 4) {
      return "ROLE_OFFERED";
    }
  };
  const CommunicationsColumns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  item: {
                    name: data?.candidate?.name,
                    contactedBy: "RECRUITER",
                    email: data?.email,
                    countryCode: data?.candidate?.countryCode,
                    phoneNumber: data?.candidate?.phoneNumber,
                    subject: row?.subject,
                    summary: row?.summary,
                    repliedTo: row?.repliedTo,
                    date: row?.createdAt,
                  },
                },
              });
            }}
          >
            {10 * (1 - 1) + index + 1}
          </span>
        );
      },
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  item: {
                    name: data?.candidate?.name,
                    contactedBy: "RECRUITER",
                    email: data?.email,
                    countryCode: data?.candidate?.countryCode,
                    phoneNumber: data?.candidate?.phoneNumber,
                    subject: row?.subject,
                    summary: row?.summary,
                    repliedTo: row?.repliedTo,
                    date: row?.createdAt,
                  },
                },
              });
            }}
          >
            {row?.subject?.name}
          </span>
        );
      },
    },

    {
      title: "Message",
      dataIndex: "summary",
      key: "summary",
      render: (_, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  item: {
                    name: data?.candidate?.name,
                    contactedBy: "RECRUITER",
                    email: data?.email,
                    countryCode: data?.candidate?.countryCode,
                    phoneNumber: data?.candidate?.phoneNumber,
                    subject: row?.subject,
                    summary: row?.summary,
                    repliedTo: row?.repliedTo,
                    date: row?.createdAt,
                  },
                },
              });
            }}
          >
            {row?.summary?.substring("0", "120")}
          </span>
        );
      },
    },

    {
      title: "Replied",
      dataIndex: "repliedTo",
      key: "repliedTo",
      align: "center",
      render: (text, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  item: {
                    name: data?.candidate?.name,
                    contactedBy: "RECRUITER",
                    email: data?.email,
                    countryCode: data?.candidate?.countryCode,
                    phoneNumber: data?.candidate?.phoneNumber,
                    subject: row?.subject,
                    summary: row?.summary,
                    repliedTo: row?.repliedTo,
                    date: row?.createdAt,
                  },
                },
              });
            }}
          >
            <span>{<img src={row.repliedTo ? GreenTick : RedCross} />}</span>
          </span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (text, row) => {
        return (
          <span
            className="cursor-pointer"
            onClick={() => {
              navigate("/support/support-detail", {
                state: {
                  item: {
                    name: data?.candidate?.name,
                    contactedBy: "RECRUITER",
                    email: data?.email,
                    countryCode: data?.candidate?.countryCode,
                    phoneNumber: data?.candidate?.phoneNumber,
                    subject: row?.subject,
                    summary: row?.summary,
                    repliedTo: row?.repliedTo,
                    date: row?.createdAt,
                  },
                },
              });
            }}
          >
            <span>{moment(row.createdAt).format("DD-MM-YYYY")}</span>
          </span>
        );
      },
    },
  ];
  const columns = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return (
          <span className="cursor-pointer">
            {(page - 1) * 10 + (index + 1)}
          </span>
        );
      },
    },
    {
      title: "Advert Name",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: (text, row) => {
        return row?.advertId?.jobTitle?.name;
      },
    },
    {
      title: "Company Name",
      dataIndex: "",
      key: "name",
      render: (text, row) => {
        return <span>{row?.campaignId?.companyId?.companyName}</span>;
      },
    },
    {
      title: "Campaign Name",
      dataIndex: "name",
      key: "name",
      render: (text, row) => {
        return row?.campaignId?.name;
      },
    },
    {
      title: "Applied Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, row) => {
        return moment(row?.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row) => {
        return (
          <span>
            <span>{progressState(row?.progress[0]?.state)}</span>
          </span>
        );
      },
    },
  ];
  const handleVerified = async (e, type) => {
    setVerifyData(e);
    setVerifyType(type);
    setVisible(true);
  };
  const getLicenceNo = (e) => {
    return e?.documentData?.licenceNumber
      ? e?.documentData?.licenceNumber
      : e?.documentData?.licenseNumber;
  };
  const handleVerifyByModal = async () => {
    setLoading(true);
    const validate = {
      profileId: verifyData?.profileId,
      id: verifyData?.id,
      isVerified: true,
    };
    try {
      if (verifyType === "SOA" || verifyType === "VOC") {
        const params = {
          profileId: verifyData?.profileId,
          isValidate: true,
          docType: verifyType,
        };
        await API_MANAGER.verifySoaVocDocument(params, verifyData?.id);
      } else if (verifyType === "medical") {
        await API_MANAGER.verifyMedicalDocument(validate);
      } else if (verifyType === "police_clearence") {
        await API_MANAGER.verifyPoliceDocument(
          { isValidate: true },
          verifyData?.id
        );
      } else if (verifyType === "qualification") {
        await API_MANAGER.verifyQualificationDocument(
          {
            isValidate: true,
          },
          verifyData?.id
        );
      } else {
        await API_MANAGER.verifyDocument(validate);
      }
      message.success("Document Verified!");
      // setValidateButtonState(false);

      getData();
      setVisible(false);
    } catch (err) {
      message.error("Facing some issues!");
      setVisible(false);
    }
    setLoading(false);
  };
  const getData = async () => {
    try {
      const response = await API_MANAGER.getCandidateDetail(id);
      setData(response?.data?.data);
      response?.data?.data?.candidate?.candidateVisa?.length > 0 &&
      !response?.data?.data?.candidate?.candidateVisa?.[0]?.isAustralian
      ? setCitizen("")
      : setCitizen("australian");
    } catch (error) {
      message.error("Something went wrong!");
    }
  };
  const handleNotify = async (values) => {
    try {
      values.profileId = data.candidate._id;
      values.type = "CUSTOM";
      await API_MANAGER.sendNotification(values);
      form1.resetFields();
      getData();
      setNotify(false);
      message.success("Successfully sent notification");
    } catch {
      message.error("Something went wrong!");
    }
  };
  const notificationColumn = [
    {
      title: "No.",
      dataIndex: "si",
      width: "80px",
      render: (_, row, index) => {
        return <span>{10 * (1 - 1) + index + 1}</span>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text, row) => {
        return (
          <span
            style={{
              display: "flex",
              flexWrap: "wrap",
              wordBreak: "break-word",
            }}
          >
            {row.message}
          </span>
        );
      },
    },

    {
      title: "Read",
      dataIndex: "isRead",
      key: "isRead",
      align: "center",
      render: (text, row) => {
        return (
          <span>
            <span>{<img src={row.isRead ? GreenTick : RedCross} />}</span>
          </span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "15%",
      render: (text, row) => {
        return (
          <span>
            <span>{moment(row.createdAt).format("DD-MM-YYYY")}</span>
          </span>
        );
      },
    },
  ];
  const getCommunications = async () => {
    try {
      const response = await API_MANAGER.getContactById(
        { contactedBy: "CANDIDATE" },
        id
      );
      setCommData(response?.data?.data);
    } catch {
      message.error("Something went wrong");
    }
  };
  let arr = [];
  let currentRole = [];
  data?.candidate?.experiences?.forEach((item) => {
    if (item.currentRole === true) {
      currentRole.push(item);
    } else {
      arr.push(item);
    }
  });
  let profExp = [...currentRole, ...arr];
  useEffect(() => {
    getData();
    getCommunications();
  }, [id]);

  return (
    <div id="modalMount" className="modal-mount">
      <div className="applicant_profile_main_container">
        <Row gutter={20}>
          <Col md={6}>
            <div className="personal_detail_container">
              <div className="personal_detail_top">
                <Row justify={"center"}>
                  {data?.candidate?.profileImage ? (
                    <Avatar
                      icon={<UserOutlined />}
                      size={window.innerWidth > 767 ? 122 : 84}
                      className="avatar-icon"
                      src={data?.candidate?.profileImage}
                    />
                  ) : (
                    <Avatar
                      icon={<UserOutlined />}
                      size={window.innerWidth > 767 ? 122 : 84}
                      className="avatar-icon text_blur"
                    />
                  )}
                </Row>
                <p className="name">{data?.candidate?.name}</p>
                <p className="citizen">
                  {citizen === "australian" ? "Australian" : "Non Australian"}
                </p>
              </div>
              <div className="personal_detail_scroll">
                <div className="detail_box firstBox">
                  <Row gutter={8} align={"middle"}>
                    <Col>
                      <img src={MailICon} />
                    </Col>
                    <Col>
                      <p className="item">Email</p>
                    </Col>
                  </Row>
                  <Row>
                    <p className="value">
                      <a className="value" href={`mail:${data?.email}`}>
                        {data?.email}
                      </a>
                    </p>
                  </Row>
                </div>
                <div className="detail_box">
                  <Row gutter={8} align={"middle"}>
                    <Col>
                      <img src={PhoneIcon} />
                    </Col>
                    <Col>
                      <p className="item">Phone Number</p>
                    </Col>
                  </Row>
                  <Row>
                    <p className="value">
                      <a
                        className="value"
                        href={`tel:${data?.candidate?.phoneNumber}`}
                      >
                        {"+"}
                        {data?.candidate?.countryCode}
                        {"-"}
                        {data?.candidate?.phoneNumber}
                      </a>
                    </p>
                  </Row>
                </div>
                <div className="detail_box">
                  <Row gutter={8} align={"middle"}>
                    <Col>
                      <img src={AddressIcon} />
                    </Col>
                    <Col>
                      <p className="item">Address</p>
                    </Col>
                  </Row>
                  <Row>
                    <p className="value">
                      {" "}
                      {data?.candidate?.street + ", "}{" "}
                      {data?.candidate?.city + ", "}
                      {data?.candidate?.state + ", "}{" "}
                      {data?.candidate?.postalCode + ", "}
                      <br />
                      {data?.candidate?.country}
                    </p>
                  </Row>
                </div>
                {/* <div className="detail_box">
                  <Row justify={"space-between"}>
                    <Col span={6}>
                      <p className="item">First Name</p>
                      <p className="value">Corey</p>
                    </Col>
                    <Col span={6}>
                      <Row justify={"end"}>
                        <div>
                          <p className="item">Last Name</p>
                          <p className="value">Evans</p>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </div> */}
                <div className="detail_box">
                  <p className="item">D.O.B</p>
                  <p className="value">
                    {data?.candidate?.dob
                      ? moment(data?.candidate?.dob).format("DD MMM YYYY")
                      : "-"}
                  </p>
                </div>
                <div className="detail_box">
                  <p className="item">Gender</p>
                  <p className="value text-capitalize">
                    {data?.candidate?.gender}
                  </p>
                </div>
                <div className="detail_box">
                  <p className="item">Ethnicity</p>
                  <p className="value">{data?.candidate?.ethnicity?.name}</p>
                </div>
                <div className="detail_box">
                  <p className="item">Discipline</p>
                  <p className="value">{data?.candidate?.discipline?.name}</p>
                </div>
                <div className="detail_box">
                  <p className="item">Availability</p>
                  <p className="value">
                    I’m {data?.candidate?.isAvailable ? "" : "not"} available
                    for work
                  </p>
                </div>
                <div className="detail_box">
                  <p className="item">Start / Notice</p>
                  <p className="value">
                    {data?.candidate?.availabilityDetails?.name}
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col md={18}>
            <div className="professional_detail_container">
              <div className="radioButtons">
                <Radio.Group
                  defaultValue={type}
                  onChange={(e) => setType(e?.target?.value)}
                >
                  <Radio.Button value="PE">
                    Professional Experience
                  </Radio.Button>
                  <Radio.Button value="QS">Qualifications</Radio.Button>
                  <Radio.Button value="OD">Other Details</Radio.Button>
                  <Radio.Button value="AD">Applied Details</Radio.Button>
                  <Radio.Button value="CM">Communication</Radio.Button>
                  <Radio.Button value="NT">Notification</Radio.Button>
                </Radio.Group>
              </div>
              <div className="professional_detail_content">
                {type === "PE" && (
                  <div className="professional_experience_container">
                    <div className="professional_summary_box">
                      <p className="title">Profile Summary Description</p>
                      <p className="desc">{data?.candidate?.profileSummary}</p>
                      {data?.candidate?.resume?.length > 0 && (
                        <div className="mt-30">
                          <p className="title">Resume</p>
                          <Row>
                            <Col span={18}>
                              <DocumentBox
                                date={data?.candidate?.resume[0]?.createdAt}
                                url={data?.candidate?.resume[0]?.resume}
                              />
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                    <div>
                      <Row gutter={18}>
                        {profExp?.map((item, index) => (
                          <Col md={24} key={index}>
                            <JobPositionCard data={item} />
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                )}
                {type === "QS" && (
                  <div className="qualification_container">
                    {data?.candidate?.qualifications.map((e, key) => {
                      return (
                        <div style={{ marginBottom: "20px" }} key={key}>
                          <div className="qualification_summary_box mb-16">
                            <Row
                              gutter={8}
                              className="align_center"
                              align={"middle"}
                              style={{ marginBottom: "10px" }}
                            >
                              <Col className="align_center">
                                <p className="title mb-0">
                                  {e.qualificationType.name}{" "}
                                </p>
                              </Col>
                              {e?.isValidate && (
                                <Col>
                                  <svg
                                    style={{ marginBottom: "16px !important" }}
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                      fill="#31B948"
                                    />
                                  </svg>
                                </Col>
                              )}

                              <Col>
                                <span>
                                  <Button
                                    onClick={() =>
                                      handleVerified(e, "qualification")
                                    }
                                    disabled={e?.isValidate}
                                  >
                                    {!e?.isValidate ? "Verify" : "Verified"}
                                  </Button>
                                </span>
                              </Col>
                              <Col>
                                <span>
                                  <img
                                    style={{ width: "25px" }}
                                    className="cursor-pointer"
                                    src={TelegramIcon}
                                    alt="send mail"
                                    onClick={() => setNotify(true)}
                                  />
                                </span>
                              </Col>
                            </Row>
                            <Row gutter={24}>
                              <Col
                                span={e?.uploadedDocumentBack ? 7 : 15}
                                onClick={() => {
                                  if (e?.uploadedDocument) {
                                    window.open(e?.uploadedDocument);
                                  }
                                }}
                              >
                                <DocumentBox
                                  date={e?.issueDate}
                                  url={e?.uploadedDocument}
                                />
                              </Col>
                              {e.uploadedDocumentBack && (
                                <Col
                                  span={7}
                                  onClick={() => {
                                    if (e?.uploadedDocument) {
                                      window.open(e?.uploadedDocument);
                                    }
                                  }}
                                >
                                  <DocumentBox
                                    date={e?.issueDate}
                                    url={e?.uploadedDocumentBack}
                                  />
                                </Col>
                              )}
                              <Col span={9}>
                                <Row justify={"space-between"}>
                                  <Col span={12}>
                                    <p className="item">Issue Date</p>
                                    <p className="value">
                                      {e?.issueDate
                                        ? moment(e?.issueDate).format(
                                            "DD MMM YYYY"
                                          )
                                        : "----"}
                                    </p>
                                  </Col>
                                  <Col span={12}>
                                    <p className="item">Expiry Date</p>
                                    <p className="value">
                                      {e?.expiryDate
                                        ? moment(e?.expiryDate).format(
                                            "DD MMM YYYY"
                                          )
                                        : "----"}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <div className="mt-30">
                              <Row gutter={24}>
                                <Col span={7}>
                                  <p className="item">Member No./ID</p>
                                  <p className="value">
                                    {e?.memberId ? e?.memberId : "----"}
                                  </p>
                                </Col>
                                <Col span={7}>
                                  <p className="item">State/Country</p>
                                  <p className="value">
                                    {e?.stateOrCountry
                                      ? e?.stateOrCountry
                                      : "----"}
                                  </p>
                                </Col>
                                <Col span={10}>
                                  <p className="item">
                                    Licence Type/Class/Category
                                  </p>
                                  <p className="value">
                                    {e?.licenceType?.map((item, index) => (
                                      <p
                                        key={index}
                                        className="w-100"
                                        style={{ margin: "0", padding: "0" }}
                                      >
                                        {index === e?.licenceType?.length - 1
                                          ? item?.name
                                          : ` ${item?.name}, `}
                                      </p>
                                    ))}
                                  </p>
                                </Col>
                                <Col xs={24}>
                                  {e?.hasSOA || e?.hasVOC ? (
                                    <div className="qualification_detail_box">
                                      {e?.hasSOA && (
                                        <div className="collapseContainer">
                                          <Collapse
                                            // size="small"
                                            expandIconPosition={"end"}
                                            bordered={false}
                                            items={[
                                              {
                                                key: "1",
                                                label: (
                                                  <Row
                                                    align={"middle"}
                                                    gutter={16}
                                                  >
                                                    <Col>
                                                      Statement of Attainment
                                                    </Col>
                                                    {e?.validateSOA && (
                                                      <Col>
                                                        <svg
                                                          style={{
                                                            marginBottom:
                                                              "16px !important",
                                                          }}
                                                          width="16"
                                                          height="16"
                                                          viewBox="0 0 16 16"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                          <path
                                                            d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                                            fill="#31B948"
                                                          />
                                                        </svg>
                                                      </Col>
                                                    )}
                                                    <Col>
                                                      <span>
                                                        <Button
                                                          onClick={() =>
                                                            handleVerified(
                                                              e,
                                                              "SOA"
                                                            )
                                                          }
                                                          disabled={
                                                            e?.validateSOA
                                                          }
                                                        >
                                                          {!e?.validateSOA
                                                            ? "Verify"
                                                            : "Verified"}
                                                        </Button>
                                                      </span>
                                                    </Col>
                                                  </Row>
                                                ),
                                                children: (
                                                  <Row gutter={24}>
                                                    <Col
                                                      span={14}
                                                      onClick={() => {
                                                        if (e?.SOADocument) {
                                                          window.open(
                                                            e?.SOADocument
                                                          );
                                                        }
                                                      }}
                                                    >
                                                      <DocumentBox
                                                        date={e?.SOAIssueDate}
                                                        url={e?.SOADocument}
                                                      />
                                                    </Col>
                                                    <Col span={110}>
                                                      <p className="item">
                                                        Issue Date
                                                      </p>
                                                      <p className="value">
                                                        {e?.SOAIssueDate
                                                          ? moment(
                                                              e?.SOAIssueDate
                                                            ).format(
                                                              "DD MMM YYYY"
                                                            )
                                                          : "----"}
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                ),
                                              },
                                            ]}
                                          />
                                        </div>
                                      )}

                                      {e?.hasVOC && (
                                        <>
                                          <Divider className="horizontalDivider" />
                                          <div className="collapseContainer">
                                            <Collapse
                                              // size="small"
                                              expandIconPosition={"end"}
                                              bordered={false}
                                              items={[
                                                {
                                                  key: "1",
                                                  label: (
                                                    <Row
                                                      align={"middle"}
                                                      gutter={16}
                                                    >
                                                      <Col>
                                                        Verification of
                                                        Competency
                                                      </Col>
                                                      {e?.validateVOC && (
                                                        <Col>
                                                          <svg
                                                            style={{
                                                              marginBottom:
                                                                "16px !important",
                                                            }}
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 16 16"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path
                                                              d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                                              fill="#31B948"
                                                            />
                                                          </svg>
                                                        </Col>
                                                      )}
                                                      <Col>
                                                        <span>
                                                          <Button
                                                            onClick={() =>
                                                              handleVerified(
                                                                e,
                                                                "VOC"
                                                              )
                                                            }
                                                            disabled={
                                                              e?.validateVOC
                                                            }
                                                          >
                                                            {!e?.validateVOC
                                                              ? "Verify"
                                                              : "Verified"}
                                                          </Button>
                                                        </span>
                                                      </Col>
                                                    </Row>
                                                  ),
                                                  children: (
                                                    <Row gutter={24}>
                                                      <Col
                                                        span={14}
                                                        onClick={() => {
                                                          if (e?.VOCDocument) {
                                                            window.open(
                                                              e?.VOCDocument
                                                            );
                                                          }
                                                        }}
                                                      >
                                                        <DocumentBox
                                                          date={e?.VOCIssueDate}
                                                          url={e?.VOCDocument}
                                                        />
                                                      </Col>
                                                      <Col span={10}>
                                                        <Row
                                                          justify={
                                                            "space-between"
                                                          }
                                                          gutter={24}
                                                        >
                                                          <Col span={12}>
                                                            <p className="item">
                                                              {" "}
                                                              Verification Type
                                                            </p>
                                                            <p className="value">
                                                              {e?.VOCType?.name
                                                                ? e?.VOCType
                                                                    ?.name
                                                                : "----"}
                                                            </p>
                                                          </Col>
                                                          <Col span={12}>
                                                            <p className="item">
                                                              Issue Date{" "}
                                                            </p>
                                                            <p className="value">
                                                              {e?.VOCIssueDate
                                                                ? moment(
                                                                    e?.VOCIssueDate
                                                                  ).format(
                                                                    "DD MMM YYYY"
                                                                  )
                                                                : "----"}
                                                            </p>
                                                          </Col>
                                                        </Row>
                                                      </Col>
                                                    </Row>
                                                  ),
                                                },
                                              ]}
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {type === "OD" && (
                  <div className="other_detail_container">
                    <div className="citizen_container">
                      {data?.candidate?.documents?.map((e, key) => {
                        return (
                          <div key={key} style={{ marginBottom: "20px" }}>
                            <div className="titleBox">
                              {e.documentType.name}
                              {/* <div className="justify_end"> */}
                              {e?.isVerfied && (
                                <svg
                                  style={{ marginBottom: "16px !important" }}
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                    fill="#31B948"
                                  />
                                </svg>
                              )}
                              <span style={{ marginLeft: "20px" }}>
                                <Button
                                  onClick={() => handleVerified(e, "other")}
                                  disabled={e?.isVerfied}
                                >
                                  {!e?.isVerfied ? "Verify" : "Verified"}
                                </Button>
                              </span>
                              <span style={{ marginLeft: "20px" }}>
                                <img
                                  style={{ width: "25px" }}
                                  className="cursor-pointer"
                                  src={TelegramIcon}
                                  alt="send mail"
                                  onClick={() => setNotify(true)}
                                />
                              </span>
                            </div>
                            <div className="detail-container">
                              <Row gutter={24}>
                                <Col
                                  span={e.documentBack ? 7 : 14}
                                  onClick={() => {
                                    window.open("");
                                  }}
                                >
                                  <DocumentBox
                                    date={e.createdAt}
                                    url={e.document}
                                  />
                                </Col>
                                {e.documentBack && (
                                  <Col
                                    span={7}
                                    onClick={() => {
                                      window.open("");
                                    }}
                                  >
                                    <DocumentBox
                                      date={e.createdAt}
                                      url={e.documentBack}
                                    />
                                  </Col>
                                )}
                                <Col span={10}>
                                  <Row justify={"space-between"} gutter={24}>
                                    <Col span={12}>
                                      <p className="item">
                                        {e?.documentType?.name === "Passport"
                                          ? "Nationality"
                                          : e?.documentType?.name ===
                                            "Proof of Age/Photo Card"
                                          ? "State"
                                          : e?.documentType?.name === "VISA"
                                          ? "Visa Type Number"
                                          : e?.documentType?.name ===
                                            "Driver Licence"
                                          ? "State"
                                          : "ID Number"}
                                      </p>
                                      <p className="value">
                                        {e?.documentType?.name === "Passport"
                                          ? e?.documentData?.nationality
                                          : e.documentType.name ===
                                            "Proof of Age/Photo Card"
                                          ? e?.documentData?.state
                                          : e.documentType.name === "VISA"
                                          ? e?.documentData?.visaTypeNo?.name
                                          : e?.documentType?.name ===
                                            "Driver Licence"
                                          ? e?.documentData?.state || "-"
                                          : e?.documentData?.idNumber || "-"}
                                      </p>
                                    </Col>
                                    <Col span={12}>
                                      <p className="item">
                                        {e.documentType.name === "Passport"
                                          ? "Passport Number"
                                          : e.documentType.name ===
                                            "Driver Licence"
                                          ? "Licence Number"
                                          : e.documentType.name === "VISA"
                                          ? "Grant No"
                                          : "ID Number"}
                                      </p>
                                      <p className="value">
                                        {e.documentType.name === "Passport"
                                          ? e?.documentData?.passportNumber
                                          : e?.documentType?.name ===
                                            "Driver Licence"
                                          ? getLicenceNo(e)
                                          : e.documentType.name === "VISA"
                                          ? e?.documentData.grantNo
                                          : e?.documentData?.idNumber}
                                      </p>
                                    </Col>
                                  </Row>
                                  {e?.documentType?.name ===
                                    "Driver Licence" && (
                                    <Row
                                      style={{ marginTop: "10px" }}
                                      justify={"space-between"}
                                      gutter={24}
                                    >
                                      <Col span={12}>
                                        <p className="item">{"Class"}</p>
                                        <p className="value">
                                          {e?.documentData?.class?.length > 0
                                            ? e?.documentData?.class?.map(
                                                (e) => {
                                                  return `${e} `;
                                                }
                                              )
                                            : "-"}
                                        </p>
                                      </Col>
                                      <Col span={12}>
                                        <p className="item">{"Conditions"}</p>
                                        <p className="value">
                                          {e?.documentData?.conditions?.length >
                                          0
                                            ? e?.documentData?.conditions?.map(
                                                (e) => {
                                                  return `${e} `;
                                                }
                                              )
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  )}
                                  <Row
                                    style={{ marginTop: "10px" }}
                                    justify={"space-between"}
                                    gutter={24}
                                  >
                                    <Col xs={12}>
                                      <p className="item">Expiry Date</p>
                                      <p className="value">
                                        {moment(
                                          e?.documentData.expiryDate
                                        ).format("DD-MMM-YYYY")}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="additional_detail_container">
                      <div className="titleBox">
                        Additional Personal Details
                      </div>
                      <div className="detail-container">
                        <Row gutter={16}>
                          <Col span={8}>
                            <p className="item">Tax File Number (TFN)</p>
                            <p className="value">
                              {data?.candidate?.otherDetails[0]?.tfnNo
                                ? data?.candidate?.otherDetails[0]?.tfnNo
                                : "----"}
                            </p>
                          </Col>
                          <Col span={8}>
                            <p className="item">USI No.</p>
                            <p className="value">
                              {data?.candidate?.otherDetails[0]?.usiNo
                                ? data?.candidate?.otherDetails[0]?.usiNo
                                : "----"}
                            </p>
                          </Col>
                        </Row>
                        {data?.candidate?.otherDetails[0]?.isSuperannuation && (
                          <>
                            <Divider className="horizontalDivider" />
                            <div>
                              <p className="title">Superannuation</p>
                              <Row gutter={16}>
                                <Col span={8}>
                                  <p className="item">Fund Name</p>
                                  <p className="value">
                                    {
                                      data?.candidate?.otherDetails[0]
                                        ?.superannuationDetails?.fundName?.name
                                    }
                                  </p>
                                </Col>
                                <Col span={8}>
                                  <p className="item">Fund USI/ABN</p>
                                  <p className="value">
                                    {
                                      data?.candidate?.otherDetails[0]
                                        ?.superannuationDetails?.fundName
                                        ?.correspondingValue
                                    }
                                  </p>
                                </Col>
                                <Col span={8}>
                                  <p className="item">Member Number</p>
                                  <p className="value">
                                    {
                                      data?.candidate?.otherDetails[0]
                                        ?.superannuationDetails?.memberNumber
                                    }
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                        {data?.candidate?.otherDetails[0]?.isLSL && (
                          <>
                            <Divider className="horizontalDivider" />
                            <div>
                              <p className="title">Long Service Leave</p>
                              <Row gutter={16}>
                                {data?.candidate?.otherDetails[0]?.lslDetails?.map(
                                  (item, index) => {
                                    return (
                                      <>
                                        <Col
                                          span={8}
                                          style={{
                                            marginTop:
                                              index !== 0 ? "12px" : "0px",
                                          }}
                                        >
                                          <p className="item">State</p>
                                          <p className="value">
                                            {" "}
                                            {item?.state?.name}
                                          </p>
                                        </Col>

                                        <Col
                                          span={16}
                                          style={{
                                            marginTop:
                                              index !== 0 ? "12px" : "0px",
                                          }}
                                        >
                                          <p className="item">Member Number</p>
                                          <p className="value">
                                            {item?.memberNumber}
                                          </p>
                                        </Col>
                                      </>
                                    );
                                  }
                                )}
                              </Row>
                            </div>
                          </>
                        )}

                        {data?.candidate?.otherDetails[0]?.isPPE && (
                          <>
                            <Divider className="horizontalDivider" />
                            <div>
                              <p className="title">PPE Details</p>
                              <Row gutter={16}>
                                <Col span={8}>
                                  <p className="item">Top</p>
                                  <p className="value">
                                    {
                                      data?.candidate?.otherDetails[0]
                                        ?.ppeDetails?.shirt?.name
                                    }
                                  </p>
                                </Col>

                                <Col span={8}>
                                  <p className="item">Bottom</p>
                                  <p className="value">
                                    {
                                      data?.candidate?.otherDetails[0]
                                        ?.ppeDetails?.pants?.name
                                    }
                                  </p>
                                </Col>
                                <Col span={8}>
                                  <p className="item">Boots</p>
                                  <p className="value">
                                    {
                                      data?.candidate?.otherDetails[0]
                                        ?.ppeDetails?.boots?.name
                                    }
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                        {data?.candidate?.otherDetails[0]?.hasTravelDetails && (
                          <>
                            <Divider className="horizontalDivider" />
                            <div>
                              <p className="title">Travel Details</p>
                              <Row gutter={16}>
                                <Col span={8}>
                                  <p className="item">Preferred Airline</p>
                                  <p className="value">
                                    {
                                      data?.candidate?.otherDetails[0]
                                        ?.travelDetails?.preferredAirline
                                    }
                                  </p>
                                </Col>

                                <Col span={8}>
                                  <p className="item">Frequent Flyer Number</p>
                                  <p className="value">
                                    {
                                      data?.candidate?.otherDetails[0]
                                        ?.travelDetails?.frequentFlyerNumber
                                    }
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    {data?.candidate?.otherDetails[0]?.hasPoliceClearance && (
                      <div className="clearance_box">
                        <Row>
                          <Col xs={5} style={{ marginTop: "7px" }}>
                            <p className="title">Police Clearance</p>
                          </Col>
                          {data?.candidate?.otherDetails[0]
                            ?.policeClearanceDetails?.isVerified && (
                            <Col>
                              <svg
                                style={{ marginBottom: "16px !important" }}
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                  fill="#31B948"
                                />
                              </svg>
                            </Col>
                          )}
                          <Col xs={3}>
                            <span>
                              <Button
                                onClick={() =>
                                  handleVerified(
                                    {
                                      id: data?.candidate?.otherDetails[0]?.id,
                                    },
                                    "police_clearence"
                                  )
                                }
                                disabled={
                                  data?.candidate?.otherDetails[0]
                                    ?.policeClearanceDetails?.isVerified
                                }
                              >
                                {!data?.candidate?.otherDetails[0]
                                  ?.policeClearanceDetails?.isVerified
                                  ? "Verify"
                                  : "Verified"}
                              </Button>
                            </span>
                          </Col>
                          <Col>
                            <span>
                              <img
                                style={{ width: "25px" }}
                                className="cursor-pointer"
                                src={TelegramIcon}
                                alt="send mail"
                                onClick={() => setNotify(true)}
                              />
                            </span>
                          </Col>
                        </Row>
                        <Row
                          gutter={24}
                          onClick={() =>
                            window.open(
                              data?.candidate?.otherDetails[0]
                                ?.policeClearanceDetails?.document
                            )
                          }
                        >
                          <Col span={14}>
                            <DocumentBox
                              date={
                                data?.candidate?.otherDetails[0]
                                  ?.policeClearanceDetails?.issueDate
                              }
                              url={
                                data?.candidate?.otherDetails[0]
                                  ?.policeClearanceDetails?.document
                              }
                            />
                          </Col>
                          <Col span={10}>
                            <Row justify={"space-between"} gutter={12}>
                              <Col span={12}>
                                <p className="item">Certifying Authority</p>
                                <p className="value">
                                  {
                                    data?.candidate?.otherDetails[0]
                                      ?.policeClearanceDetails
                                      ?.certifyingAuthority?.name
                                  }
                                </p>
                              </Col>
                              <Col span={12}>
                                <p className="item">Issue Date </p>
                                <p className="value">
                                  {moment(
                                    data?.candidate?.otherDetails[0]
                                      ?.policeClearanceDetails?.issueDate
                                  ).format("DD MMM YYYY")}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        {/* <Divider className="horizontalDivider" /> */}
                      </div>
                    )}
                    {data?.candidate?.medicalAssessment[0]
                      ?.hasMedicalAssessment &&
                      data?.candidate?.medicalAssessment[0]?.medicals?.map(
                        (e, key) => {
                          return (
                            <div className="clearance_box" key={key}>
                              <Row>
                                <Col xs={5} style={{ marginTop: "7px" }}>
                                  <p className="title">Medical Assessment</p>
                                </Col>
                                {e?.isValidate && (
                                  <Col>
                                    <svg
                                      style={{
                                        marginBottom: "16px !important",
                                      }}
                                      width="16"
                                      height="16"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM11.0234 5.71406L7.73281 10.2766C7.68682 10.3408 7.62619 10.3931 7.55595 10.4291C7.48571 10.4652 7.40787 10.4841 7.32891 10.4841C7.24994 10.4841 7.17211 10.4652 7.10186 10.4291C7.03162 10.3931 6.97099 10.3408 6.925 10.2766L4.97656 7.57656C4.91719 7.49375 4.97656 7.37813 5.07812 7.37813H5.81094C5.97031 7.37813 6.12187 7.45469 6.21562 7.58594L7.32812 9.12969L9.78438 5.72344C9.87813 5.59375 10.0281 5.51562 10.1891 5.51562H10.9219C11.0234 5.51562 11.0828 5.63125 11.0234 5.71406Z"
                                        fill="#31B948"
                                      />
                                    </svg>
                                  </Col>
                                )}
                                <Col xs={3}>
                                  <span>
                                    <Button
                                      onClick={() =>
                                        handleVerified(
                                          {
                                            profileId:
                                              data?.candidate
                                                ?.medicalAssessment[0]
                                                ?.profileId,
                                            id: e?._id,
                                          },
                                          "medical"
                                        )
                                      }
                                      disabled={e?.isValidate}
                                    >
                                      {!e?.isValidate ? "Verify" : "Verified"}
                                    </Button>
                                  </span>
                                </Col>
                                <Col>
                                  <span>
                                    <img
                                      style={{ width: "25px" }}
                                      className="cursor-pointer"
                                      src={TelegramIcon}
                                      alt="send mail"
                                      onClick={() => setNotify(true)}
                                    />
                                  </span>
                                </Col>
                              </Row>
                              <Row gutter={24}>
                                <Col span={14}>
                                  <DocumentBox
                                    date={
                                      data?.candidate?.medicalAssessment[0]
                                        ?.createdAt
                                        ? data?.candidate?.medicalAssessment[0]
                                            ?.createdAt
                                        : null
                                    }
                                    url={e?.document}
                                  />
                                </Col>
                                <Col span={10}>
                                  <Row justify={"space-between"} gutter={12}>
                                    <Col span={12}>
                                      <p className="item">
                                        {citizen === "australian"
                                          ? "Medical Assessment"
                                          : "Verification Type"}
                                      </p>
                                      <p className="value">
                                        {e?.medicalAssessment?.name}
                                      </p>
                                    </Col>
                                    <Col span={12}>
                                      <p className="item">Issue Date </p>
                                      <p className="value">
                                        {moment(e?.issueDate).format(
                                          "DD MMM YYYY"
                                        )}
                                      </p>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      )}
                  </div>
                )}
                {type === "AD" && (
                  <Row className="professional_experience_container">
                    <Col
                      xs={24}
                      className="professional_summary_box table_container"
                    >
                      <p className="title">Applied Details List</p>
                      <Table
                        columns={columns}
                        dataSource={data?.candidate?.appliedJobs}
                        pagination={false}
                        className="w-100 table"
                        rowKey={"id"}
                        loading={loading}
                        scroll={{ y: 400 }}
                      />
                    </Col>
                    <div className="w-100">
                      <CustomPagination
                        currentPage={page}
                        setCurrentPage={setPage}
                        total={10}
                        itemPerPage={10}
                      />
                    </div>
                  </Row>
                )}
                {type === "CM" && (
                  <Row>
                    <Col md={24} className="create_resource table_container">
                      <Table
                        columns={CommunicationsColumns}
                        dataSource={commData}
                        pagination={false}
                        className="w-100"
                        rowKey={"id"}
                        loading={loading}
                      />
                    </Col>
                  </Row>
                )}
                {type === "NT" && (
                  <Row>
                    <Col md={24} className="create_resource table_container">
                      <Table
                        columns={notificationColumn}
                        dataSource={data?.candidate?.notifications}
                        pagination={false}
                        className="w-100"
                        rowKey={"id"}
                        loading={loading}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <UpdateApplicantProfileStatus
          visible={updateStatusModal}
          setVisible={setUpdateStatusModal}
        />
        <Modal
          title={"Notification"}
          open={notify}
          onCancel={() => {
            form1.resetFields();
            setNotify(false);
          }}
          centered
          footer={false}
        >
          <Form form={form1} layout="vertical" onFinish={handleNotify}>
            <Row>
              <Col xs={24}>
                <Form.Item name="message">
                  <Input className="input" placeholder="Enter message" />
                </Form.Item>
                <Col md={24} className="buttonCol">
                  <Form.Item>
                    <Button
                      className="btn"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
      <Modal
        className="candidateDeleteModal"
        centered
        onCancel={() => {
          setVisible(false);
        }}
        open={visible}
        footer={null}
        title={`Verify Document?`}
      >
        <Row className="header">
          <Col>
            <p className="desc">
              Are you sure you want to verify the document?
            </p>
          </Col>
        </Row>

        <Row align={"middle"} gutter={16} justify={"end"}>
          <Col>
            <Button
              className="confirmBtn"
              onClick={() => handleVerifyByModal()}
              loading={loading}
            >
              Okay
            </Button>
          </Col>
          <Col>
            <Button
              className="cancelBtn"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default CandidateDetail;
